import { Component, Vue, Watch } from 'vue-property-decorator';
import { SidemenuItem } from '@/components/sidemenu/Sidemenu';
import { DateTime } from 'luxon';

@Component<ReportStatistics>({})
export default class ReportStatistics extends Vue {
  public $pageTitle = 'Dossier statistieken';

  protected isLoading = true;

  protected items: SidemenuItem[] = [
    {
      title: 'Dashboard',
      link: '/rapportage',
      icon: {
        key: 'dashboard',
      },
    },
    ...this.hasManagementInfo
      ? [
        {
          title: 'PDF rapportages',
          link: '/rapportage/pdf-rapportages',
          icon: {
            key: 'picture_as_pdf',
          },
        },
        {
          title: 'Opgeleverd Matrix',
          link: '/rapportage/opgeleverd-matrix',
          icon: {
            key: 'line_style',
          },
        },
        {
          title: 'Box plot doorlooptijd',
          link: '/rapportage/box-plot-doorlooptijd',
          icon: {
            key: 'tune',
          },
        },
      ]
      : [],
  ];

  protected bureauTools: SidemenuItem[] = [
    {
      title: 'Termijn bewaking',
      link: '/rapportage/termijn-bewaking',
      icon: {
        key: 'alarm_on',
      },
    },
    {
      title: 'Kwaliteit monitor',
      link: '/rapportage/kwaliteit-monitor',
      icon: {
        key: 'find_in_page',
      },
    },
    ...this.hasManagementInfo
      ? [
        {
          title: 'Logboek FTR IMG',
          link: '/rapportage/ftr-logboek?organization=img',
          icon: {
            key: 'list',
          },
        },
        {
          title: 'Logboek FTR Bureau',
          link: '/rapportage/ftr-logboek',
          icon: {
            key: 'list',
          },
        },
      ]
      : [],
    {
      title: 'Dossiers uit termijn',
      link: '/rapportage/dossiers-uit-termijn',
      icon: {
        key: 'alarm_off',
      },
    },
    {
      title: 'Mutatie OHW',
      link: '/rapportage/production-statistics-incomming',
      icon: {
        key: 'perm_data_setting',
      },
      visible: this.hasManagementInfo,
    },
    {
      title: 'Rapport aantallen OHW',
      link: '/rapportage/rapport-aantallen-ohw',
      icon: {
        key: 'perm_data_setting',
      },
      visible: true,
    },
    {
      title: 'Productie aantallen',
      link: this.$router
        .resolve({
          path: '/rapportage/production',
          query: {
            filter: 'rapportage-production',
            headers: 'rapportage-production',
            organizations: '',
            'production-from': DateTime.local().startOf('week').toFormat('yyyy-LL-dd'),
            'production-till': DateTime.local().endOf('week').toFormat('yyyy-LL-dd'),
            'production-status': 'standard',
          },
        })
        .href,
      icon: {
        key: 'perm_data_setting',
      },
      visible: true,
    },
    {
      title: 'Output TCers',
      link: '/rapportage/production-statistics-outgoing',
      icon: {
        key: 'support_agent',
      },
      visible: this.hasManagementInfo,
    },
    {
      title: 'Doelstellingen',
      link: '/rapportage/doelstellingen',
      icon: {
        key: 'flare',
      },
      visible: this.hasManagementInfo,
    },
    {
      title: 'Trendlijn',
      link: '/rapportage/trendlijn',
      icon: {
        key: 'show_chart',
      },
      visible: this.$store.state.isBETA && this.hasManagementInfo,
    },
    {
      title: 'Ouderdoms analyse',
      link: '/rapportage/ouderdoms-analyse',
      icon: {
        key: 'history_toggle_off',
      },
      visible: this.hasManagementInfo,
    },
  ];

  protected imgTools: SidemenuItem[] = [
    {
      title: 'IMG Fact Sheet',
      link: '/rapportage/fact-sheet',
      icon: {
        key: 'show_chart',
      },
      visible: this.$store.state.isServiceOrganization,
    },
  ];

  public mounted() {
    this.initialize();
  }

  protected initialize() {
    this.emitBreadcrumb();
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Rapportage' },
        ],
      });
  }

  protected get hasManagementInfo(): boolean {
    return this.$store.state.Auth.has_management_info;
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
