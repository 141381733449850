var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "grid-list-lg": "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { sm4: "", md3: "", xl2: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                        _c("h2", { staticClass: "elementSubTitle" }, [
                          _vm._v("Rapportage")
                        ])
                      ]),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c(
                          "div",
                          { staticClass: "elementPanel" },
                          [
                            _c(
                              "v-layout",
                              {
                                staticClass: "item__list",
                                attrs: { wrap: "" }
                              },
                              [_c("Sidemenu", { attrs: { items: _vm.items } })],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                        _c("h2", { staticClass: "elementSubTitle" }, [
                          _vm._v("Aansturing Bureaus")
                        ])
                      ]),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c(
                          "div",
                          { staticClass: "elementPanel" },
                          [
                            _c(
                              "v-layout",
                              {
                                staticClass: "item__list",
                                attrs: { wrap: "" }
                              },
                              [
                                _c("Sidemenu", {
                                  attrs: { items: _vm.bureauTools }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _vm.$store.state.isServiceOrganization
                    ? _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                            _c("h2", { staticClass: "elementSubTitle" }, [
                              _vm._v("Statistieken IMG")
                            ])
                          ]),
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c(
                              "div",
                              { staticClass: "elementPanel" },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    staticClass: "item__list",
                                    attrs: { wrap: "" }
                                  },
                                  [
                                    _c("Sidemenu", {
                                      attrs: { items: _vm.imgTools }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { sm8: "", md9: "", xl10: "" } },
                [_c("router-view")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }